import axios from 'axios';
import {
    GET_JOB_LIST_SUCCESS,
    GET_JOB_LIST_FAIL,
    GET_JOB_SUCCESS,
    GET_JOB_FAIL,
} from "./types"

export const get_job_list = () => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json'
        }
    };

    try{

        const res = await axios.get(`${process.env.REACT_APP_EXTERNAL_API_URL}/job/main/`, config)

        if(res.status === 200){
            dispatch({
                type: GET_JOB_LIST_SUCCESS,
                payload: res.data
            });
        }else{
            dispatch({
                type: GET_JOB_LIST_FAIL
            });
        }

    }catch(err){
        dispatch({
            type: GET_JOB_LIST_FAIL
        });
    }
}

export const get_job_list_page = (page) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json'
        }
    };

    try{

        const res = await axios.get(`${process.env.REACT_APP_EXTERNAL_API_URL}/job/main/?p=${page}`, config)

        if(res.status === 200){
            dispatch({
                type: GET_JOB_LIST_SUCCESS,
                payload: res.data
            });
        }else{
            dispatch({
                type: GET_JOB_LIST_FAIL
            });
        }

    }catch(err){
        dispatch({
            type: GET_JOB_LIST_FAIL
        });
    }
}

export const get_job = (id) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_EXTERNAL_API_URL}/job/main/${id}/`, config);

        if (res.status === 200) {
            dispatch({
                type: GET_JOB_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_JOB_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_JOB_FAIL
        });
    }
}