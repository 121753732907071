import { connect } from "react-redux";
import Layout from "hocs/layouts/Layout";
import Navbar from "components/navigation/Navbar";
import Footer from "components/navigation/Footer";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { get_job } from "redux/actions/job/job";
import moment from "moment";
import "moment/locale/es";
import DOMPurify from "dompurify";

function JobDetail({ get_job, job }) {
	const params = useParams();
	const id = params.id;

	useEffect(() => {
		window.scrollTo(0, 0);
		get_job(id);
	}, []);

	return (
		<Layout>
			<header className="flex justify-center">
				<Navbar />
			</header>
			{job && job.id === id ? (
				<div className="mt-24 mb-4 p-6 rounded-xl grid grid-cols-1 md:grid-cols-12 max-w-7xl mx-auto shadow-2xl">
					<div className="bg-white w-full col-span-5 px-4 rounded-2xl">
						<div className="h-72 md:h-96 rounded-lg overflow-hidden">
							<img
								className="h-full w-full object-cover"
								src={`${process.env.REACT_APP_EXTERNAL_API_URL}/${job.image}`}
								alt="Banner principal de la oferta de trabajo"
							/>
							<div
								className="bg-gray-200 mix-blend-multiply"
								aria-hidden="true"
							/>
						</div>
					</div>
					<div className="col-span-7">
						<h1 className="text-2xl font-bold tracking-tight text-gray-700 sm:text-3xl lg:text-4xl">
							{job.title}
						</h1>
						<div className="mt-4">
							Prioridad: <span className="text-gray-500"> {job.priority}</span>
						</div>
						<div className="flex items-center gap-4 mt-4">
							<div className="w-20 h-20 rounded-lg overflow-hidden">
								<img src={`${process.env.REACT_APP_EXTERNAL_API_URL}/${job.user.picture}`} className="h-full w-full object-cover" alt={`Logo de ${job.user.enterprise}`} />
							</div>
							<div className="">
								<span className="text-xl font-medium">{job.user.enterprise}</span>
								<span className="block text-gray-400">{job.profile.address}</span>
							</div>
						</div>
						<div className="flex items-center gap-3">
							Redes sociales:
							<div className="flex gap-4 mt-2">
								{job.profile.facebook ? (
									<a href={job.profile.facebook} target="_blank" rel="nofollow">
										<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-facebook" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" /></svg>
									</a>
								) : ""}
								{job.profile.X ? (
									<a href={job.profile.X} target="_blank" rel="nofollow">
										<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4l11.733 16h4.267l-11.733 -16z" /><path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772" /></svg>
									</a>
								) : ""}
								{job.profile.instagram ? (
									<a href={job.profile.instagram} target="_blank" rel="nofollow">
										<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-instagram" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" /><path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" /><path d="M16.5 7.5l0 .01" /></svg>
									</a>
								) : ""}
							</div>
						</div>
						<div className="min-w-0 flex-1 py-4">
							<div className="">
								<p className="my-4 text-lg font-regular text-gray-500 leading-8">
									{job.description}
								</p>
								<span className="mt-2 text-gray-400 capitalize">
									{moment(job.created).format("MMM D, YYYY")}
								</span>
							</div>
						</div>
					</div>

					<div className="relative overflow-hidden bg-white">
						<div className="relative px-4 sm:px-6 lg:px-8">
							<div className="prose prose-lg max-w-4xl prose-indigo mx-auto text-gray-600">
								<div
									dangerouslySetInnerHTML={{
										__html: DOMPurify.sanitize(job.content),
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			) : (
				<>Loading</>
			)}
			<Footer />
		</Layout>
	);
}

const mapStateToProps = (state) => ({
	job: state.job.job,
});

export default connect(mapStateToProps, {
	get_job,
})(JobDetail);
