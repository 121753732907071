import { Link } from "react-router-dom"
import {useEffect}from'react'

function JobCardHorizontal({data,index}){
  return(
    <>
      <div key={data.title} className="bg-gray-50 px-4 py-4 rounded-lg shadow-xl group">
        <div className='mb-4'>
          <img className="h-48 w-full object-cover bg-white rounded-md" src={`${process.env.REACT_APP_EXTERNAL_API_URL}/${data.image}`} alt={`Banner de ${data.title}`}/>
        </div>
        <Link to={`/empleos/${data.id}`} className="mt-4 block">
          <p className="text-xl font-semibold text-gray-700 group-hover:text-green-corpo line-clamp-1">{data.title}</p>
          <p className="mt-1 text-base font-semibold text-gray-500">Empresa: {data.user.enterprise}</p>
          <p className="mt-3 text-base text-gray-500 line-clamp-2">{data.description}</p>
        </Link>
        <div className="mt-4 flex items-center justify-center">
          <div className="">
            <Link to={`/empleos/${data.id}`}>
              <span className="bg-green-corpo hover:bg-green-700 transition ease-linear duration-75 text-white px-4 py-1.5 rounded-md">Ver oferta</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
export default JobCardHorizontal