import SmallSetPagination from "components/pagination/SmallSetPagination";
import JobCardHorizontal from "./JobCardHorizontal";
import { Link } from "react-router-dom";

function JobList({ jobs, get_job_list_page, count }) {
  return (
    <div className="overflow-hidden bg-white">
      <h1 className="text-3xl font-bold text-center text-green-corpo">Bolsa de empleo</h1>
      <div className="mt-6 mb-6 grid gap-16 pt-6 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-5">
        {jobs && jobs.map((job, index) => (
            <JobCardHorizontal data={job} key={index} index={index} />
          ))}
      </div>
      <SmallSetPagination
        list_page={get_job_list_page}
        list={jobs}
        count={count}
      />
    </div>
  );
}
export default JobList;
